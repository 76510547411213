body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
}

header {
  background-color: #2c3e50;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
  box-sizing: border-box;
}
header .logo h1 {
  margin: 0;
}

header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

header nav ul li {
  margin: 0;
}

header nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

header nav ul li a:hover {
  background-color: #1abc9c;
  border-radius: 5px;
}

header .google-signup:hover {
  background-color: #c23321;
}


#hero {
  background-color: #34495e;
  color: white;
  padding: 150px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#hero h2 {
  margin-top: 0;
  font-size: 48px;
  font-weight: bold;
}

#hero p {
  font-size: 20px;
  margin: 20px 0;
}

#hero .slogan {
  font-style: italic;
  font-size: 24px;
  margin-bottom: 20px;
}

#hero button {
  background-color: #e67e22;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 80px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

#hero button:hover {
  background-color: #d35400;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 50px; 
  height: auto; 
  margin-right: 10px; 
}

.logo h1 {
  margin: 0;
  font-size: 24px; 
  font-weight: bold;
}

.text{
  text-align: justify;
}
.text1{
  text-align:center;
}

#services {
  padding: 80px 50px;
  background-color: #ecf0f1;
  text-align: center;
}

#services h2 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 36px;
}

#services ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

#services ul li {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  flex-basis: calc(33.333% - 60px);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#services ul li:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

#services ul li h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

#services ul li p {
  font-size: 16px;
  color: #7f8c8d;
}


#about {
  padding: 80px 50px;
  background-color: #bdc3c7;
  text-align: center;
}

#about h2 {
  margin-bottom: 30px;
  font-size: 36px;
}

#about p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
}


#contact {
  background-color: #34495e;
  display: flex;
  color: #ddd;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#contact form {
  width: 100%;
  max-width: 600px;
  background: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-element {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea.form-control {
  resize: vertical;
}

.btn-submit {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-submit:hover {
  background-color: #2980b9;
}



/* Footer Styles */
footer {
  background-color: #2c3e50;
  color: white;
  text-align: center;
  padding: 20px;
}

footer p {
  margin: 0;
}
/* Media Query for Responsive Design */
@media (max-width: 768px) {
  header nav ul {
    display: none; /* Hide navbar on mobile screens */
  }

  header .hamburger {
    display: block;
    cursor: pointer;
  }

  #hero h2 {
    font-size: 36px;
  }

  #hero p {
    font-size: 18px;
  }

  #hero button {
    padding: 12px 25px;
    font-size: 16px;
  }

  #services ul li {
    flex-basis: calc(100% - 40px);
  }

  #services ul {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  #hero h2 {
    font-size: 28px;
  }

  #hero p {
    font-size: 16px;
  }

  #hero button {
    padding: 10px 20px;
    font-size: 14px;
  }

  #services ul li {
    padding: 20px;
  }
}

.social-icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.social-icons a {
  color: white;
  margin: 0 15px;
  font-size: 24px;
}

.social-icons a:hover {
  color: #3498db;
}